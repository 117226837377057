import { useContext } from 'react';
import Button from './button';
import { useSession } from 'next-auth/react';
import { BiChevronRight } from 'react-icons/bi';
import router from 'next/router';
import { IoBag, IoStorefront } from 'react-icons/io5';
import { CartContext } from '../layouts-and-providers/cart-provider';

type NavBarProps = {
	isLoginPage: boolean;
};

const NavBar = ({ isLoginPage }: NavBarProps): JSX.Element => {
	const { data: session } = useSession();
	const { cart } = useContext(CartContext);

	const cartItemCount =
		cart === undefined || cart === null
			? 0
			: cart.cartItems.reduce((acc, cartItem) => {
					return acc + cartItem.quantity;
			  }, 0);

	const buttonClass =
		'font-bold text-white rounded-lg px-2 mr-4 rounded-full hover:text-slate-400';
	const buttons = (
		<>
			{session && session.user && session.user.name && (
				<div className="grid grid-flow-col auto-cols-max divide-x">
					<div className="flex">
						{/* Mobile shopping cart & store icons */}
						<div className="flex sm:hidden bg-gren-700 items-center mr-6">
							{/* mobile store */}
							<div
								className="flex items-center justify-center mr-6"
								onClick={() => {
									router.push('/store/mobile');
								}}
							>
								<IoStorefront
									color="white"
									size={30}
									className={'active:fill-slate-400'}
								></IoStorefront>
							</div>

							{/* mobile bag/cart */}
							{/* TODO: this whole thing should be a button */}
							<div className="bg-rd-200 flex items-center justify-center">
								<button
									onClick={() => {
										router.push('/store/cart');
									}}
									className="flex items-center justify-center"
								>
									<IoBag
										color="white"
										className={'active:fill-slate-400'}
										size={30}
									></IoBag>
									{cartItemCount > 0 && (
										<span className="absolute -mr-8 -mt-4 h-5 w-5 rounded-full bg-green-400/80 text-white text-xs flex justify-center items-center items">
											<span>{cartItemCount}</span>
										</span>
									)}
								</button>
							</div>
						</div>

						{/* Only display home text button on desktop */}
						<div className="hidden sm:block">
							<Button
								text="Home"
								type="link"
								href="/"
								className={buttonClass + ''}
							/>
						</div>

						{/* Display shop button with text on desktop */}
						<div className="hidden sm:flex">
							<Button
								text="Shop"
								type="link"
								href="/store/products"
								className={buttonClass + ' mr-5'}
							/>
							{cartItemCount > 0 && (
								<span className="absolute -mt-1 ml-12 h-5 w-5 rounded-full bg-blue-400 text-white text-sm flex justify-center items-center">
									<span>{cartItemCount}</span>
								</span>
							)}
						</div>
					</div>
					<div className="justify-center flex">
						{/* Display first initial of name in a circle on mobile */}
						<div className="sm:hidden flex items-center justify-center mx-2">
							<button
								className="rounded-full ring-1 ring-white w-8 h-8 mx-2 bg-gradient-to-br from-blue-500 via-indigo-600 to-pink-700 active:opacity-60 text-white text-xl font-semibo"
								onClick={() => {
									router.push('/account');
								}}
							>
								{session?.user &&
									session.user.name?.charAt(0).toUpperCase()}
							</button>
						</div>

						{/* display full name on desktop*/}
						<div className="hidden sm:flex">
							<Button
								text={session.user.name}
								type="link"
								href="/account"
								className={
									buttonClass + ' justify-center ml-4 mr-2'
								}
							/>
						</div>
					</div>
				</div>
			)}
		</>
	);

	return (
		<>
			<nav className="sticky top-0 z-40">
				<div className="flex justify-between items-center bg-gradient-to-r from-black/70 via-slate-800/60 to-slate-600/70 flex-wrap py-2">
					<Button
						text="IMAGE FOUNTAIN"
						type="link"
						href="/"
						className="text-xl text-white font-bold ml-3 sm:ml-8 tracking-wide active:text-slate-400"
					/>

					<div className="flex items-center">
						{isLoginPage ? (
							<div className="sm:hidden">
								<button
									type="button"
									className="bg-green-200 ring-2 ring-black rounded-md flex items-center px-2 py-1 mr-3"
									onClick={() =>
										router.push('/login-confirm')
									}
								>
									<div className="flex justify-center items-center">
										<p className="mr-1 font-semibold">
											Get Started
										</p>

										<BiChevronRight size={25} />
									</div>
								</button>
							</div>
						) : (
							<div className="sm:mr-4">{buttons}</div>
						)}
					</div>
				</div>
			</nav>
		</>
	);
};
export default NavBar;
