import { Listbox, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import cn from 'classnames';
export interface ListOption {
	label: string;
	disabled?: boolean;
}

type ListProps = {
	selected: ListOption;
	options: ListOption[];
	onChange: (...args: any) => void;
	className?: string;
};

const List = ({
	selected,
	options,
	onChange,
	className,
}: ListProps): JSX.Element => {
	return (
		<div className={className}>
			<Listbox value={selected} onChange={onChange}>
				<div className="relative">
					<Listbox.Button className="relative flex justify-center mx-auto cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
						<span className="block truncate">{selected.label}</span>
						<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
							<ChevronUpDownIcon
								className="h-5 w-5 text-gray-400"
								aria-hidden="true"
							/>
						</span>
					</Listbox.Button>
					<Transition
						as={Fragment}
						leave="transition ease-in duration-100"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Listbox.Options className="ml-0 absolute items-center z-50 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
							{options.map((option, idx) => (
								<Listbox.Option
									key={idx}
									disabled={option.disabled}
									className={({ active }) =>
										cn(
											'relative cursor-default select-none py-2 pl-10 pr-4',
											{
												'bg-amber-100 text-amber-900':
													active,
												'text-gray-900': !active,
												'text-gray-200':
													option.disabled,
											},
										)
									}
									value={option}
								>
									{({ selected }) => (
										<>
											<span
												className={`block truncate ${
													selected
														? 'font-medium'
														: 'font-normal'
												}`}
											>
												{option.label}
											</span>
											{selected ? (
												<span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
													<CheckIcon
														className="h-5 w-5"
														aria-hidden="true"
													/>
												</span>
											) : null}
										</>
									)}
								</Listbox.Option>
							))}
						</Listbox.Options>
					</Transition>
				</div>
			</Listbox>
		</div>
	);
};

export default List;
