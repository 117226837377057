import Link from 'next/link';
import cn from 'classnames';
import { MouseEventHandler } from 'react';

interface CommonProps {
	text?: string;
	className?: string;
	children?: JSX.Element;
	disabled?: boolean;
	hoverColor?: string;
}

type Props =
	| {
			type?: 'link';
			href: string;
			isSubmit?: never;
			onClick?: never;
	  }
	| {
			type?: 'button';
			href?: never;
			isSubmit?: boolean;
			onClick: MouseEventHandler;
	  };

type AllProps = CommonProps & Props;

export const CoolButton = ({
	text,
	type = 'button',
	href = '',
	hoverColor = 'bg-white',
	isSubmit = false,
	className,
	onClick,
	disabled,
	children,
}: AllProps): JSX.Element => {
	if (type === 'link') {
		return (
			<>
				<Link className={cn(className)} href={disabled ? '' : href}>
					<span>{text}</span>
				</Link>
			</>
		);
	} else if (type === 'button') {
		return (
			<button
				onClick={onClick}
				disabled={disabled}
				type={isSubmit ? 'submit' : 'button'}
				className={cn(
					'relative inline-flex group items-center justify-center cursor-pointer disabled:pointer-events-none shadow-xl active:shadow-none overflow-hidden',
					className,
				)}
			>
				<span
					className={
						'absolute w-0 h-0 transition-all duration-200 ease-out bg-white rounded-full group-hover:w-[120%] group-hover:h-[120%] opacity-10 ' +
						hoverColor
					}
				></span>
				<span className="relative">
					{children ? children : <span>{text}</span>}
				</span>
			</button>
		);
	}
	return <>ERROR</>;
};

type CoolMailtoButtonProps = {
	to: string;
	buttonText: string;
	subject: string;
	className?: string;
};

export const CoolMailtoButton = ({
	to,
	buttonText,
	subject,
	className,
}: CoolMailtoButtonProps) => {
	return (
		<a
			href={`mailto:${to}?subject=${subject}`}
			className={cn(
				'relative inline-flex group items-center justify-center cursor-pointer disabled:pointer-events-none shadow-xl active:shadow-none overflow-hidden shadow-2xl flex-shrink-0 text-md font-bold text-white py-1 px-2 ring-2 ring-slate-100 rounded bg-gradient-to-r from-blue-600 to-pink-700 disabled:opacity-50',
				className,
			)}
		>
			<span
				className={
					'absolute w-0 h-0 transition-all duration-200 ease-out bg-white rounded-full group-hover:w-[120%] group-hover:h-[120%] opacity-10 bg-white'
				}
			></span>
			<span className="relative">{buttonText}</span>
		</a>
	);
};

export const Button = ({
	text,
	type,
	href,
	isSubmit = false,
	className,
	onClick,
	disabled,
	children,
}: AllProps): JSX.Element => {
	if (type === 'link') {
		return (
			<>
				<Link className={cn(className)} href={disabled ? '' : href}>
					<span>{text}</span>
				</Link>
			</>
		);
	} else if (type === 'button') {
		return (
			<>
				<button
					onClick={onClick}
					disabled={disabled}
					type={isSubmit ? 'submit' : 'button'}
					className={cn('', className)}
				>
					{text}
					{children}
				</button>
			</>
		);
	}

	return <>ERROR</>;
};
export default Button;
