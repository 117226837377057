import React, { useEffect, useRef, useState } from 'react';
import Head from 'next/head';
import NavBar from '../global/nav-bar';
import WAVES from 'vanta/dist/vanta.waves.min';
import * as THREE from 'three';

interface LayoutProps {
	title: string;
	children: React.ReactNode;
	enableWaves?: boolean;
	isLoginPage?: boolean;
	feedbackFloaterPositon?: 'top-right' | 'bottom-right';
}

interface Vanta {
	destroy: () => void;
}

const Layout = ({
	title,
	children,
	enableWaves = false,
	isLoginPage = false,
}: LayoutProps): JSX.Element => {
	const [vantaEffect, setVantaEffect] = useState<Vanta | null>(null);
	const layoutRef = useRef(null);

	useEffect(() => {
		if (!vantaEffect && enableWaves && typeof window !== undefined) {
			setVantaEffect(
				WAVES({
					el: layoutRef.current,
					THREE: THREE,
					mouseControls: false,
					touchControls: false,
					gyroControls: false,
				}),
			);
		}
		return () => {
			if (vantaEffect) vantaEffect.destroy();
		};
	}, [vantaEffect, enableWaves]);

	return (
		<>
			<Head>
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover, minimal-ui"
				/>
				<meta name="title" content="Image Fountain" />
				<meta
					name="description"
					content="Bring your imagination to life"
				/>
				<meta property="og:type" content="website" />
				<meta property="og:url" content="https://imagefountain.com" />
				<meta property="og:title" content="Image Fountain" />
				<meta
					property="og:description"
					content="Bring your imagination to life"
				/>
				<meta
					property="og:image"
					content="https://res.cloudinary.com/de8sb8oyd/image/upload/v1675195096/site_assets/logo_jtmxzq.png"
				/>
				<meta property="twitter:card" content="summary_large_image" />
				<meta
					property="twitter:url"
					content="https://imagefountain.com"
				/>
				<meta property="twitter:title" content="Image Fountain" />
				<meta
					property="twitter:description"
					content="Bring your imagination to life"
				/>
				<meta
					property="twitter:image"
					content="https://res.cloudinary.com/de8sb8oyd/image/upload/v1675195096/site_assets/logo_jtmxzq.png"
				/>
				<title>
					{title != null && title !== ''
						? title + ' | Image Fountain'
						: 'Image Fountain'}
				</title>
			</Head>

			<div
				id="root"
				// if we're on mobile login page then show h-full,
				// everywhere else should be h-screen.
				// if we use h-full on mobile inference for example, this will lead to rendering a much larger vanta wave as you scroll
				className={
					(isLoginPage ? 'h-full sm:h-screen' : 'h-screen') +
					' flex flex-col bg-gradient-to-br from-indigo-500 via-blue-500 to-pink-500'
				}
				ref={layoutRef}
			>
				<NavBar isLoginPage={isLoginPage} />
				{children}
			</div>
		</>
	);
};
export default Layout;
